import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../../components/Layout';
import Cover from '../../../components/Elements/Cover';
import PageSection from '../../../components/Elements/PageSection';
import Row, { Col } from '../../../components/Elements/Grid';

import GetReady from '../../../components/Parts/GetReady';
import Icon from '../../../components/Elements/Icon';
import withTranslations from '../../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('managedHostingVoorWie');

  return (
    <Layout className="voor-wie">
      <Seo title={t('seoTitle')} />
      <Cover image="avocado">
        <PageSection center>
          <h1>{t('title')}</h1>
          <p>{t('intro.p1')}</p>
          <p>{t('intro.p2')}</p>
        </PageSection>
        <PageSection grid slide className="types">
          <Row>
            <Col>
              <div className="icon">
                <Icon type="paint-brush" />
              </div>
              <div className="details">
                <h3>{t('types.digitalAgency.title')}</h3>
                <p>{t('types.digitalAgency.description.p1')}</p>
                <p>{t('types.digitalAgency.description.p2')}</p>
                <p>{t('types.digitalAgency.description.p3')}</p>
              </div>
            </Col>
            <Col>
              <div className="icon">
                <Icon type="keyboard" />
              </div>
              <div className="details">
                <h3>{t('types.softwareAgency.title')}</h3>
                <p>{t('types.softwareAgency.description.p1')}</p>
                <p>{t('types.softwareAgency.description.p2')}</p>
                <p>{t('types.softwareAgency.description.p3')}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="icon">
                <Icon type="hand-holding-heart" />
              </div>
              <div className="details">
                <h3>{t('types.fans.title')}</h3>
                <p>{t('types.fans.description.p1')}</p>
                <p>{t('types.fans.description.p2')}</p>
              </div>
            </Col>
            <Col>
              <div className="icon">
                <Icon type="building" />
              </div>
              <div className="details">
                <h3>{t('types.enterprise.title')}</h3>
                <p>{t('types.enterprise.description.p1')}</p>
              </div>
            </Col>
          </Row>
        </PageSection>
        <GetReady image="footerVoorwie" />
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
